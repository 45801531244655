import { CheckoutRoutes } from '@ecom/types/checkout.types'

export default defineNuxtRouteMiddleware((to) => {
  const routeNameTo = (to.meta?.name || to?.name) as string
  const { localePath } = useT3Utils()

  if (
    routeNameTo === CheckoutRoutes.CHECKOUT
    || (routeNameTo === CheckoutRoutes.SUMMARY && !to.query.OrderID)
  ) {
    return navigateTo(localePath('checkout/cart'))
  }
})
